
import {  IonFab, IonFabButton, IonIcon ,IonRefresher,  IonRefresherContent} from '@ionic/vue';
import {searchOutline, refresh} from 'ionicons/icons';
import BaseLayout from "@/components/BaseLayout.vue";
import GroupCard from "@/components/card/GroupCard.vue";
import MissingCard from "@/components/card/MissingCard.vue";
import {mapGetters} from "vuex";

export default  {
  name: 'Groups',
  components: {
    GroupCard,
    IonFab,
    IonFabButton,
    BaseLayout,
    MissingCard,
    IonIcon,
    IonRefresher,
    IonRefresherContent
  },
    setup() {
        return {
          searchOutline,
          refresh
        }
    },
  computed:{
    ...mapGetters(['groups',
      'hasGroups',
    ])
  },
  methods:{
    refreshData($event){
      this.$store.dispatch('account');
      $event.target.complete();
    }
  }

}
