import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_group_card = _resolveComponent("group-card")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_missing_card = _resolveComponent("missing-card")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, { title: "Groups" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_refresher, {
        slot: "fixed",
        "pull-factor": "0.5",
        "pull-min": "100",
        "pull-max": "150",
        onIonRefresh: $options.refreshData
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher_content, {
            "pulling-icon": $setup.refresh,
            refreshingSpinner: "crescent"
          }, null, 8, ["pulling-icon"])
        ]),
        _: 1
      }, 8, ["onIonRefresh"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, (group) => {
        return (_openBlock(), _createBlock(_component_group_card, {
          key: group.id,
          group: group
        }, null, 8, ["group"]))
      }), 128)),
      _createVNode(_component_ion_fab, {
        vertical: "bottom",
        horizontal: "end",
        slot: "fixed"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab_button, {
            "router-link": "/groups/find",
            color: "secondary"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: $setup.searchOutline }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (!_ctx.hasGroups)
        ? (_openBlock(), _createBlock(_component_missing_card, {
            key: 0,
            missing: 'group'
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}